@import "~material-icons/iconfont/material-icons.css";
.mat-stepper-horizontal-line {
    border-color: transparent;
    position: relative;
}
.mat-stepper-horizontal-line:after {
    content: "";
    position: absolute;
    left: 50%;
}
.mat-horizontal-stepper-header-container {
    box-shadow: inset 0 0 0 1px rgb(215, 215, 215);
    border-radius: 10px;
    padding: 0;
    padding-left: 50px;
    padding-right: 300px;
}
.mat-stepper-horizontal {
    background: transparent !important;
}
.item {
    background: transparent !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.danger {
    background-color: #ff0000 !important;
    color: white !important;
}

.success {
    background-color: #00b050 !important;
    color: black !important;
}

.warning {
    background-color: #ffff00 !important;
    color: black !important;
}

.ligth-green {
    background-color: #99ff33 !important;
    color: black !important;
}

.ligth-yello {
    background-color: #ffcc66 !important;
    color: black !important;
}

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.blur {
    background-color: rgba(255, 255, 255, 0.9);
}

*:disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}

.a-left {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.p-column-filter-overlay{
    z-index: 9999 !important;
}
