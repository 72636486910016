$enable-ltr: true;
$enable-rtl: true;
// If you want to override variables do it here
@import "variables";

// Import styles
@import "@coreui/coreui/scss/coreui";
// If you want to add something do it here
@import "custom";

@import 'bootstrap/dist/css/bootstrap.min.css';

@import "@ng-select/ng-select/themes/default.theme.css";
/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "variables";


// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";

// Some temp fixes
@import "fixes";

// Prism.js
@import "examples";

// If you want to add something do it here
@import "custom";

@font-face {
    font-family: "Roboto-Regular";
    src: local("Roboto-Regular"), url(../assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Roboto-Bold";
    src: local("Roboto-Bold"), url(../assets/fonts/Roboto/Roboto-Bold.ttf) format("truetype");
}

body {
    font-family: "Roboto-Regular", serif !important;
    background-color: white !important;
    font-size: 1em !important;
}

.regular-button {
    background-color: #017B5F;
    padding: 8px 30px;
    color: white;
    border-radius: 20px;
}

.roboto-bold-font {
    font-family: "Roboto-Bold", serif !important;
}

.large-welcome-button {
    background-color: rgb(252, 209, 22);
    padding: 8px 30px;
    color: #017B5F;
    border-radius: 20px;
    font-family: "Roboto-Bold", serif !important;
    width: 189px;
    cursor: pointer;
}

.bg-green {
    background-color: rgb(1, 123, 95) !important;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

::-webkit-scrollbar {
    width: 5px;
    background-color: white;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px;
}

::-webkit-scrollbar-thumb {
    background: #FCD116;
}

::-webkit-scrollbar-thumb:hover {
    background: #FCD116;
    cursor: pointer;
}

::-webkit-scrollbar-button {
    height: 28px;
    background: #FCD116;
}

.btn:focus,
.btn.focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 #FFDB58 !important;
}

.clearfix {
    clear: both;
}

.pointer {
    cursor: pointer;
}

.no-pointer {
    cursor: default !important;
}

#how_it_works .pointer:hover {
    .counter-ico .ico-circle {
        height: 60px;
        width: 60px;
        line-height: 1.8;
        box-shadow: 0 0 0 10px #000;
    }
}

.img-avatar,
.avatar>img,
.img-circle {
    max-width: 100% !important;
    width: 35px !important;
    height: 35px !important;
    border-radius: 50em !important;
}

.notification {
    color: #fff;
    font-size: 18px !important;
    font-weight: bold;
}

.notification-success {
    background-color: #017B5F;
}

.notification-warning {
    background-color: #FCD116;
}

.notification-danger {
    background-color: #CE1126;
}

.notification-info {
    background-color: #36C8F4;
}

.text-red {
    color: #CE1126;
}

.is-invalid {
    color: #CE1126;
}

.dashboard-sidebar {
    margin-top: 4em;
}

table {
    width: 100%;
}

.mat-form-field {
    font-size: 14px;
    width: 100%;
}

.icon-click {
    cursor: pointer;
}

.table-action i {
    margin-left: 2em;
}

.mat-success {
    background-color: #017B5F;
    color: #000;
}

.mat-icon {
    cursor: pointer;
}

.app-header .navbar-brand {
    display: inline-flex;
    text-align: center;
    justify-content: center;
    width: 100px !important;
    height: 50px !important;
    padding: 0;
    margin-right: 0;
    background-color: transparent;
}

.app-body .sidebar {
    flex: 0 0 200px;
    order: -1;
}

.sidebar-fixed .sidebar {
    position: fixed;
    z-index: 1019;
    width: 200px;
    height: 100vh;
    color: #fff;
}

.sidebar {
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
    color: #fff !important;
    background: #017B5F;
}

.sidebar .nav {
    width: 200px;
    flex-direction: column;
    min-height: 100%;
    padding: 0;
    color: #fff !important;
}

.sidebar .sidebar-minimizer {
    position: relative;
    flex: 0 0 50px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    border: 0;
    // width: 250px;
}

.sidebar-minimized .sidebar .sidebar-minimizer {
    position: relative;
    bottom: 0;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.2);
}

.sidebar .nav-dropdown.open .nav-link .active {
    color: #000 !important;
    border-left: 0;
}

.sidebar .nav-link .active {
    color: #000 !important;
    background: #fff;
}

tbody tr:hover {
    color: #000 !important;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.hide {
    display: none;
}

.hideCell {
    display: none;
}

.mat-tab-label .mat-tab-label-content {
    font-weight: 900 !important;
    font-family: "Roboto-Bold", serif;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #FCD116;
}

.small-width {
    width: 5%;
}

.btn-pill {
    color: #fff;
}

a {
    cursor: pointer;
}

ul.breadTitle {
    padding: 10px 16px;
    list-style: none;
    background-color: #eee;
}

/* Display list items side by side */
ul.breadTitle li {
    display: inline;
    font-size: 14px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadTitle li+li:before {
    padding: 5px;
    color: black;
    content: "/ ";
}

/* Add a color to all links inside the list */
ul.breadTitle li a {
    color: #017B5F;
    text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadTitle li a:hover {
    color: rgba(1, 123, 95, 0.8);
    text-decoration: underline;
}

table {
    width: 100% !important;
}

.gr-modal-full .modal {
    position: fixed;
    margin: 0 auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
}

.gr-modal-full .modal-dialog {
    position: fixed;
    margin: 0;
    min-width: 100%;
    height: 100%;
    padding: 0;
}

.gr-modal-full .modal-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
}

.gr-modal-full .modal-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 50px;
    padding: 10px;
    border-radius: 0;
}

.gr-modal-full .modal-body {
    position: absolute;
    top: 40px;
    bottom: 15px;
    width: 100%;
    overflow: auto;
}

.bi {
    font-size: 50px;
    color: #CE1126;
    cursor: pointer;
}

.bi-sm {
    font-size: 25px;
    color: #CE1126;
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.new-line {
    white-space: pre-wrap;
}

table thead tr th,
table tbody tr td,
table tfoot tr td {
    display: table-cell !important;
    vertical-align: middle !important;
}

.table thead tr {
    background-color: #017B5F !important;
    color: #fff !important;
}

table thead tr th {
    text-align: center !important;
}

.nowrap {
    white-space: nowrap !important;
}

.date-size {
    width: 5% !important;
}

.w-5 {
    width: 5% !important;
}

.w-10 {
    width: 10% !important;
}

.w-15 {
    width: 15% !important;
}

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-30 {
    width: 30% !important;
}

.w-35 {
    width: 35% !important;
}

.w-40 {
    width: 40% !important;
}

.w-45 {
    width: 45% !important;
}

.w-50 {
    width: 50% !important;
}

.w-55 {
    width: 55% !important;
}

.w-60 {
    width: 60% !important;
}

.w-65 {
    width: 65% !important;
}

.w-70 {
    width: 70% !important;
}

.w-75 {
    width: 75% !important;
}

.w-80 {
    width: 80% !important;
}

.w-85 {
    width: 85% !important;
}

.w-90 {
    width: 90% !important;
}

.w-95 {
    width: 95% !important;
}

.w-100 {
    width: 100% !important;
}

.component-bg {
    background-color: #4169E1 !important;
    color: white !important;
}

.sub-component-bg {
    background-color: #6BCAE2 !important;
    color: white !important;
}

.activity-bg {
    background-color: #0AFFFF !important;
    color: white !important;
}

.activity-bg-active {
    background-color: springgreen !important;
    color: white !important;
}

.component-bg:hover {
    color: black !important;
}

.sub-component-bg:hover {
    color: black !important;
}

.activity-bg:hover {
    color: black !important;
}

.activity-bg-active:hover {
    color: black !important;
}

.component-cashout {
    background-color: #abcf91 !important;
    color: black !important;
}

.sub-component-cashout {
    background-color: #fbe5d8 !important;
    color: black !important;
}

.co-contractor {
    background-color: #f4b186 !important;
    color: black !important;
}

.preparation {
    background-color: #315496 !important;
    color: black !important;
}

.execution {
    background-color: #fffe02 !important;
    color: black !important;
}

.profile-img {
    text-align: center;
}

.profile-img img {
    width: 100%;
    height: 150px;
}

.profile-img .file {
    position: relative;
    overflow: hidden;
    margin-top: -25%;
    width: 100%;
    border: none;
    border-radius: 0;
    font-size: 15px;
    background: #212529b8;
}

.profile-img .file input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
}

.reduce-size {
    width: 5%;
    padding-left: 1px;
    padding-right: 1px;
}

.fixed-size {
    width: 7.5%;
    padding-left: 1px;
    padding-right: 1px;
}

.break-spaces {
    white-space: break-spaces;
}

.input-group > .form-control {
    height: auto;
}

.semon-color {
    background-color: #FA8072;
}

.color-legend > div {
    height: 15px;
    width: 15px;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    margin: 10px;
}

task-bg {
    background-color: #D4D4D6 !important;
    color: black;
}

.card {
    height: 100%;
    display: flex;
}

.mb-10 {
    margin-bottom: 6rem !important;
}

c-sidebar-nav-link-content {
    white-space: break-spaces !important;
}
